import { db, auth } from '../config/main_config';
import jsSHA from "jssha";

export const intercomInit = async () => {
    auth.onAuthStateChanged(async (user) => {
        if (user) {
            db.collection('users').doc(auth.currentUser.uid).get().then(async (doc) => {
                if (!window.location.href.includes("overduetasks")) {
                    console.log(import.meta.env.VITE_intercomSecret)
                    console.log(new Date(user.metadata.creationTime).getTime())
                    const userID = user.uid;
                    const intercomSecret = import.meta.env.VITE_intercomSecret;
                    const hmac = new jsSHA("SHA-256", "TEXT", {
                        hmacKey: { value: `${intercomSecret}`, format: "TEXT" },
                      });
                    hmac.update(userID);
                    const hash = hmac.getHash("HEX");
                    window.intercomSettings = {
                      api_base: "https://api-iam.intercom.io",
                      app_id: "fxjeu3e6",
                      name: `${doc.data().firstName} ${doc.data().lastName}`, // Full name
                      user_id: `${user.uid}`,
                      user_hash: hash,
                      email: `${user.email}`, // the email for your user
                      created_at: new Date(user.metadata.creationTime).getTime() / 1000, // Signup date as a Unix timestamp
                    };
                    (function () {
                        var w = window;
                        var ic = w.Intercom;
                        if (typeof ic === "function") {
                            ic('reattach_activator');
                            ic('update', w.intercomSettings);
                        } else {
                            var d = document;
                            var i = function() {
                                i.c(arguments);
                            };
                            i.q = [];
                            i.c = function(args) {
                                i.q.push(args);
                            };
                            w.Intercom = i;
                            var l = function() {
                                var s = d.createElement('script');
                                s.type = 'text/javascript';
                                s.async = true;
                                s.src = 'https://widget.intercom.io/widget/fxjeu3e6';
                                var x = d.getElementsByTagName('script')[0];
                                x.parentNode.insertBefore(s, x);
                            };
                            if (document.readyState === 'complete') {
                                l();
                            } else if (w.attachEvent) {
                                w.attachEvent('onload', l);
                            } else {
                                w.addEventListener('load', l, false);
                            }
                        }
                    })();
                    window.Intercom("update", window.intercomSettings);
                  }
            })            
        }

    })
}