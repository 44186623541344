import { auth } from "../config/main_config";

export const signInWithEmailAndPassword = async (email, password) => {
    try {
        const result = await auth.signInWithEmailAndPassword(email, password);
        console.log(result)
        return window.location.href = "index.html";
    } catch (error) {
        console.log(error);
    }
}  

